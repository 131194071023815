<template>
  <div class="vue-nav-wrap d-block d-md-flex">
    <template v-if="!isProOrProPlus">
      <a href="/purchases" class="btn btn-info btn-sm d-block d-md-inline my-2 mx-2">GET HiiKER PRO+</a>
    </template>
    <template v-if="user">
      <span class="mx-2 d-none d-lg-block">
        <div class="dropdown">
          <a aria-expanded="false" data-bs-toggle="dropdown" class="dropdown-toggle" type="button">
            <template v-if="isProOrProPlus">
              <img class="PRO-tag" src="../img/PRO-PLUS.png" alt="User pro status indicator." width="35"/>
            </template>
            <img alt="User Profile Picture"
                 class="rounded-circle img-fluid nav-profile-img"
                 :src="user.avatar_url"
                 title="Your profile picture"
                 width="60"
                 height="60">
          </a>
          <a aria-expanded="false" data-bs-toggle="dropdown" class="badge badge-pill badge-primary dropdown-toggle" type="button">
            <span class="material-icons">arrow_drop_down</span>
          </a>
          <div aria-labelledby="dropdownMenuButton" class="dropdown-menu bg-dark">
            <a class="dropdown-item d-flex align-items-center" href="/user/profile">
              <span class="material-icons mr-2">account_circle</span>
              Profile
            </a>
            <a v-if="user.admin" class="dropdown-item d-flex align-items-center" href="/admin">
              <span class="material-icons mr-2">admin_panel_settings</span>
              Admin
            </a>
            <a class="dropdown-item d-flex align-items-center" href="/logout">
              <span class="material-icons mr-2">logout</span>
              Logout
            </a>
          </div>
        </div>
      </span>
    </template>
    <template v-else>
      <a href="/login" class="btn btn-secondary btn-sm d-block d-md-inline my-2 mx-2">LOGIN</a>
    </template>
    <LoadingDialog :show-loading-dialog="loading"/>
  </div>
</template>

<script lang="ts">
import firebase from '../js/firebase';
import graphClient from '../utils/graphql/graph_client';
import {
  UpdateUser,
} from '../utils/graphql/user-graph';
import axiosClient from '../utils/helpers/axios_client';
import { RevenueCatWrapper } from '../utils/RevenueCatWrapper';
import LoadingDialog from '../utils/shared_components/LoadingDialog.vue';

export default {
  data() {
    return {
      user: null,
      isProOrProPlus: false,
      loading: false,
      intervalId: null,
    };
  },
  components: {
    LoadingDialog,
  },
  async beforeCreate() {
    const { data } = await axiosClient.get('/user/current');
    this.user = data;
    if (!window.user) {
      window.user = data;
    }
    window.bus.$on('user:updated', async () => {
      const response = await axiosClient.get('/user/current');
      this.user = response.data;
      window.user = this.user;
    });
    try {
      this.isProOrProPlus = await RevenueCatWrapper.isProOrProPlus();
    } catch (e) {
      alert(e);
    }
    const currentUrl = new URL(window.location.href);
    const redirectStatus = currentUrl.searchParams.get('redirect_status');
    if (redirectStatus === 'succeeded') {
      this.loading = true;
      await this.checkApi();
      this.intervalId = setInterval(this.checkApi, 5000);
    }
    // Dynamically load the Google Identity Services script and initialize One Tap only if no user is signed in.
    if (!this.user) {
      this.loadGoogleScript()
        .then(() => {
          this.initializeOneTap();
        })
        .catch((error) => {
          console.error('Failed to load Google Identity script:', error);
        });
    }
  },
  methods: {
    async loadGoogleScript() {
      return new Promise((resolve, reject) => {
        if (document.getElementById('google-identity-script')) {
          resolve(true);
          return;
        }
        const script = document.createElement('script');
        script.src = 'https://accounts.google.com/gsi/client';
        script.async = true;
        script.defer = true;
        script.id = 'google-identity-script';
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    },
    initializeOneTap() {
      // Prevent auto-select so the prompt is shown even if the user was previously dismissed.
      google.accounts.id.disableAutoSelect();

      google.accounts.id.initialize({
        client_id: window.googleClientId,
        callback: this.handleCredentialResponse,
        // You can add additional One Tap configuration here if needed.
      });
      console.log('Google One Tap initialized');
      google.accounts.id.prompt();
    },
    handleCredentialResponse(response) {
      console.log('One Tap ID token:', response.credential);
      const credential = firebase.auth.GoogleAuthProvider.credential(response.credential);

      firebase.auth().signInWithCredential(credential)
        .then( async (userCredential) => {
          console.log('User signed in:', userCredential.user);
          const token = await userCredential.user.getIdToken();
          const redirectUrl = `/firebase/auth.json?accessToken=${token}`;
          graphClient.setHeader('Authorization', token);
          await axiosClient.get(redirectUrl);
          await graphClient.request(UpdateUser, {
            user: { title: 'Full name if you have it', displayName: 'displayName if you have it' }
          });
          window.bus.$emit('user:updated');
        })
        .catch((error) => {
          console.error('Error signing in with credential:', error);
        });
    },
    async checkApi() {
      if (!this.loading) return;
      this.isProOrProPlus = await RevenueCatWrapper.isProOrProPlus();
      if (this.isProOrProPlus) {
        this.loading = false;
        clearInterval(this.intervalId);
        this.$toasted.show('PURCHASE SUCCESSFUL', {
          position: 'top-right',
          icon: 'done',
          duration: 10000,
          containerClass: 'paymentSuccessToast',
          closeOnSwipe: true,
          singleton: true,
        });
        console.log('I\'m pro');
      } else {
        console.log('Not pro yet');
      }
    },
  },
};
</script>

<style scoped>
.PRO-tag {
  position: absolute;
  top: 2px;
  left: 27px;
  box-shadow: 5px 4px 5px 0px rgb(0 0 0 / 30%);
  -webkit-box-shadow: 5px 4px 5px 0px rgb(0 0 0 / 30%);
  -moz-box-shadow: 5px 4px 5px 0px rgba(0,0,0,0.3);
}
@media (max-width: 720px) {
  .vue-nav-wrap {
    width: 100%;
  }
}
</style>
